.wall {
    background-color: rgba(22, 22, 61, 0.56);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 40;
    animation: changeBg 400ms linear;
  }

  

  .bg {
    background: rgb(18, 18, 18);
  }